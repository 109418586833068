import React from "react"
import styled from "styled-components"

import usage from "../images/35.svg"
import star from "../images/star.svg"
import rabbit from "../images/rabbit.svg"
import handmade from "../images/handmade.svg"

const BenefitsLine = styled.div`
  background: var(--black);
  text-transform: uppercase;
  color: var(--white);
  font-size: 1.6rem;
  font-weight: 300;
  padding: 4rem 0;
  img {
    height: 3rem;
    margin-bottom: 1rem;
  }
`

const Benefits = () => {
  return (
    <BenefitsLine className="center">
      <div className="container grid-4x">
        <div className="center">
          <img
            src={usage}
            alt="+35"
            style={{ height: "2rem", margin: "1rem 0" }}
          />
          <div>Utilizações</div>
        </div>
        <div className="center">
          <img src={star} alt="Star icon" />
          <div>Premium Silk</div>
        </div>
        <div className="center">
          <img src={handmade} alt="Handmade icon" />
          <div>Feitas à mão</div>
        </div>
        <div className="center">
          <img src={rabbit} alt="Rabbit icon" />
          <div>Cruelty Free - Vegan</div>
        </div>
      </div>
    </BenefitsLine>
  )
}

export default Benefits
