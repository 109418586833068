import React from "react"
import styled from "styled-components"

const Star = styled.svg`
  height: 2rem;
  width: 2rem;
  margin-right: 0.5rem;
`

const Rating = ({ stars }) => {
  const array = Array(stars)
    .fill()
    .map((_, i) => i * i)

  return (
    <div style={{ padding: "2rem" }}>
      {array.map(i => (
        <Star
          width="35"
          height="35"
          viewBox="0 0 35 35"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          key={i}
        >
          <path
            d="M33.8688 12.3071L23.3764 10.7126L18.6861 0.769211C18.558 0.496968 18.3472 0.27658 18.0869 0.142619C17.434 -0.194443 16.6405 0.0864424 16.3141 0.769211L11.6237 10.7126L1.1314 12.3071C0.842131 12.3503 0.577654 12.4929 0.375163 12.709C0.130363 12.9721 -0.00453293 13.3261 0.000116312 13.6932C0.00476556 14.0602 0.14858 14.4103 0.399958 14.6666L7.99129 22.406L6.1978 33.3347C6.15574 33.5889 6.18265 33.8503 6.27546 34.0894C6.36827 34.3284 6.52328 34.5355 6.72291 34.6871C6.92253 34.8387 7.15879 34.9288 7.40489 34.9472C7.65099 34.9655 7.89708 34.9114 8.11526 34.791L17.5001 29.6313L26.8849 34.791C27.1411 34.9336 27.4387 34.9811 27.7238 34.9292C28.4428 34.7996 28.9263 34.0866 28.8024 33.3347L27.0089 22.406L34.6002 14.6666C34.8068 14.4548 34.9432 14.1783 34.9845 13.8758C35.0961 13.1195 34.5919 12.4195 33.8688 12.3071Z"
            fill="#FF9FBC"
          />
        </Star>
      ))}
    </div>
  )
}

export default Rating
