import React from "react"

import Seo from "../components/seo"
import Layout from "../components/layout"
import Hero from "../components/hero"
import ProductLine from "../components/productline"
import StepByStep from "../components/stepbystep"
import InstagramFeed from "../components/instagramfeed"
import BestSellers from "../components/bestSellers"
import BestOfYou from "../components/bestOfYou"
import Testimonials from "../components/testimonials"
import BestSellersMagic from "../components/bestSellersMagic"

const IndexPage = () => (
  <Layout>
    <Seo title="Eyeliner e Pestanas Magnéticas" />
    <Hero />
    <ProductLine />
    <StepByStep
      title="Simples. Prático. Aplicação em menos de 2min."
      background="#ffebf1"
    />
    <BestSellersMagic />
    <Testimonials />
    <BestSellers />
    <BestOfYou />
    <InstagramFeed />
  </Layout>
)

export default IndexPage
