import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
  a {
    background: rgba(0, 0, 0, 1);
    position: relative;
  }
  a:hover .image {
    opacity: 0.3;
  }
  a:hover {
    border-bottom: 0;
    background: rgba(0, 0, 0, 0.8);
  }
  .image {
    opacity: 0.3;
    display: block;
    width: 100%;
    height: auto;
    transition: 0.3s ease-in-out;
    backface-visibility: hidden;
  }
  .middle {
    color: white;
    font-family: "glacial_bold";
    text-transform: uppercase;
    font-size: 5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transition: 0.3s ease-in-out;
  }
  @media (max-width: 640px) {
    .middle {
      font-size: 2rem;
    }
  }
`

const ProductLine = () => {
  return (
    <Grid>
      <Link to="/magic-collection/" className="imghover">
        <StaticImage
          src="../images/magic-collection.jpg"
          className="image"
          placeholder="blurred"
          alt="Magic Collection"
        />
        <div className="middle">Magic Collection</div>
      </Link>
      <Link to="/magnetic-collection/" className="imghover">
        <StaticImage
          src="../images/magnetic-collection.jpg"
          className="image"
          placeholder="blurred"
          alt="Magnetic Collection"
        />
        <div className="middle">Magnetic Collection</div>
      </Link>
    </Grid>
  )
}

export default ProductLine
