import React from "react"
import styled from "styled-components"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import Rating from "./rating"

import alexandrasalvador from "../images/testimonials/alexandra-salvador.jpg"
import sofiagomes from "../images/testimonials/sofiagomes.jpg"
import annicolemar from "../images/testimonials/annicolemar.jpg"
import katyvm from "../images/testimonials/katyvm.jpg"
import marseeahmua from "../images/testimonials/marseeahmua.jpg"
import patricia from "../images/testimonials/patriciaferreira_makeup.jpg"
import ritamcasimiro from "../images/testimonials/ritamcasimiro.jpg"
import vanessa from "../images/testimonials/vanessapais98.jpg"

const TestimonialsSection = styled.section`
  background: #fff8fa;
  h2 {
    margin-bottom: 6rem;
  }
  h3 {
    margin-top: 2.4rem;
    font-size: 2.4rem;
  }
  .carousel .slide {
    background: transparent;
  }
  .carousel .slide img {
    width: 150px;
    border-radius: 100%;
    cursor: pointer;
  }
  .carousel .slide p {
    font-size: 1.4rem;
    margin-bottom: 1rem !important;
    max-width: 50ch;
    margin: 0 auto;
  }
  .carousel .control-dots .dot {
    border: 2px solid var(--beige);
    box-shadow: none;
    opacity: 0.5;
  }
  .carousel .control-dots .dot.selected {
    opacity: 1 !important;
  }
  .handle {
    color: var(--beige);
    font-size: 1.2rem;
  }
  .carousel .thumbs-wrapper {
    text-align: center;
  }
  .carousel .thumb.selected,
  .carousel .thumb:hover {
    border-color: var(--beige);
  }
`
const Testimonials = () => {
  return (
    <TestimonialsSection>
      <div className="container">
        <div className="center">
          <h2>O que dizem de nós</h2>
        </div>
        <Carousel
          showThumbs={true}
          showArrows={false}
          showStatus={false}
          showIndicators={false}
          infiniteLoop
        >
          <div>
            <img
              src={alexandrasalvador}
              alt="@alexandrasalvadormakeup.artist profile"
              loading="lazy"
            />
            <h3 className="title">Alexandra Salvador</h3>
            <h4 className="handle">
              <a
                href="https://instagram.com/alexandrasalvadormakeup.artist"
                target="_blank"
                rel="noreferrer"
              >
                @alexandrasalvadormakeup.artist
              </a>
            </h4>
            <Rating stars={5} />
            <blockquote>
              <p>
                A Boomsy é uma marca que tenho a certeza que vou sempre
                recomendar, conseguiu criar um produto único que ainda não
                estava no mercado de Portugal e que veio revolucionar a
                auto-maquilhagem e consequentemente a auto-estima de muita
                gente.
              </p>
              <p>
                Para mim as pestanas magnéticas além de bonitas e realistas são
                muito práticas de colocar e acabam por trazer um conforto no uso
                sem haver necessidade de colocar cola na raiz das pestanas..
              </p>
              <p>
                Têm vindo a evoluir ainda mais em qualidade e vou continuar
                sempre a acompanhar e a ser cliente assídua! Considero uma
                marca/produto 5 estrelas!
              </p>
            </blockquote>
          </div>
          <div>
            <img
              src={sofiagomes}
              alt="@sofiagomes.makeup profile"
              loading="lazy"
            />
            <h3 className="title">Sofia Gomes</h3>
            <h4 className="handle">
              <a
                href="https://instagram.com/sofiagomes.makeup"
                target="_blank"
                rel="noreferrer"
              >
                @sofiagomes.makeup
              </a>
            </h4>
            <Rating stars={5} />
            <blockquote>
              <p>
                Super práticas e fáceis de usar! Aplicação em 2 segundos 😍
                comprei o modelo Bold e são realmente incríveis!
              </p>
              <p>Ansiosa por experimentar os novos modelos!</p>
            </blockquote>
          </div>

          <div>
            <img
              src={patricia}
              alt="@patriciaferreira_makeup profile"
              loading="lazy"
            />
            <h3 className="title">Patrícia Ferreira</h3>
            <h4 className="handle">
              <a
                href="https://instagram.com/patriciaferreira_makeup"
                target="_blank"
                rel="noreferrer"
              >
                @patriciaferreira_makeup
              </a>
            </h4>
            <Rating stars={5} />
            <blockquote>
              <p>
                São sem dúvida as melhores pestanas que já experimentei.
                Práticas, fáceis, lindas, são de uma qualidade inexplicável,
                toda a gente devia ter umas pestanas Boomsy.
              </p>
              <p>Conquistaram o meu coração!</p>
            </blockquote>
          </div>
          <div>
            <img src={katyvm} alt="@katy.v.m profile" loading="lazy" />
            <h3 className="title">KATY</h3>
            <h4 className="handle">
              <a
                href="https://instagram.com/katy.v.m"
                target="_blank"
                rel="noreferrer"
              >
                @katy.v.m
              </a>
            </h4>
            <Rating stars={5} />
            <blockquote>
              <p>
                As pestanas Boomsy são das mais práticas e confortáveis que já
                experimentei até hoje. A aplicação das pestanas é tão fácil e
                rápida.
              </p>
              <p>
                O melhor é que elas aguentam o dia todo{" "}
                <span role="img" aria-labelledby="smile">
                  😀
                </span>{" "}
                Deixam qualquer look de maquilhagem incrível.
              </p>
            </blockquote>
          </div>
          <div>
            <img
              src={marseeahmua}
              alt="@mar.see.ah.mua profile"
              loading="lazy"
            />
            <h3 className="title">Márcia Duarte</h3>
            <h4 className="handle">
              <a
                href="https://instagram.com/mar.see.ah.mua"
                target="_blank"
                rel="noreferrer"
              >
                @mar.see.ah.mua
              </a>
            </h4>
            <Rating stars={5} />
            <blockquote>
              <p>
                Comprei o modelo Air, para uso pessoal. Já há algum tempo que
                tinha curiosidade em experimentar esta nova maneira de colocar
                pestanas. Como no dia a dia não tenho muito tempo para me
                maquilhar, vieram me facilitar a vida.
              </p>
              <p>
                São confortáveis, muito rápidas de aplicar, e apesar de eu optar
                por uma maquilhagem mais simples, usar as Boomsy fazem toda a
                diferença, no olhar!
              </p>
            </blockquote>
          </div>
          <div>
            <img src={vanessa} alt="@vanessapais98 profile" loading="lazy" />
            <h3 className="title">Vanessa Pais</h3>
            <h4 className="handle">
              <a
                href="https://instagram.com/vanessapais98"
                target="_blank"
                rel="noreferrer"
              >
                @vanessapais98
              </a>
            </h4>
            <Rating stars={5} />
            <blockquote>
              <p>
                Tenho a dizer que encontrei as melhores pestanas magnéticas
                (nunca tinha experimentado nenhumas) mas são as únicas que
                consigo colocar finalmente!!!!
              </p>
              <p>
                O melhor kit é sem dúvida desta marca incrível e quero muito
                testar os novos modelos e os novos eyeliners{" "}
                <span role="img" aria-labelledby="heart">
                  💖
                </span>
              </p>
              <p>Melhor marca de pestanas magnéticas e é portuguesa!</p>
            </blockquote>
          </div>
          <div>
            <img src={annicolemar} alt="@annicolemar profile" loading="lazy" />
            <h3 className="title">Nicole Martinez</h3>
            <h4 className="handle">
              <a
                href="https://instagram.com/annicolemar"
                target="_blank"
                rel="noreferrer"
              >
                @annicolemar
              </a>
            </h4>
            <Rating stars={5} />
            <blockquote>
              <p>
                Super fácil, eu sou uma banana a meter pestanas com cola
                portanto sempre usei magnéticas da Ardell, mas estas conseguem
                ainda ser mais fáceis porque é só mesmo encaixar em cima!
              </p>
            </blockquote>
          </div>
          <div>
            <img
              src={ritamcasimiro}
              alt="@ritamcasimiro profile"
              loading="lazy"
            />
            <h3 className="title">Rita Casimiro</h3>
            <h4 className="handle">
              <a
                href="https://instagram.com/ritamcasimiro"
                target="_blank"
                rel="noreferrer"
              >
                @ritamcasimiro
              </a>
            </h4>
            <Rating stars={5} />
            <blockquote>
              <p>
                Amei as minhas pheme{" "}
                <span role="img" aria-labelledby="love">
                  😍
                </span>{" "}
                muito leves e fáceis de aplicar.
              </p>
              <p>Ficam lindas e super naturais!</p>
            </blockquote>
          </div>
        </Carousel>
      </div>
    </TestimonialsSection>
  )
}

export default Testimonials
