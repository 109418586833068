import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

const BestSellersSection = styled.section`
  h2 {
    margin-bottom: 6rem;
    span {
      color: var(--beige);
    }
  }
  .description {
    padding: 1rem 0 1.6rem;
    font-weight: 700;
    font-size: 1.6rem;
    text-transform: uppercase;
    text-align: center;
    color: var(--beige);
  }
  a {
    border-bottom: none;
  }
`

const BestSellersMagic = () => {
  return (
    <BestSellersSection>
      <div className="large-container">
        <div className="center">
          <h2>
            Magic Collection
            <br />
            <span>A Não Perder</span>
          </h2>
        </div>
        <div className="grid-3x">
          <Link to="/loja/boomsy-sparkle/">
            <StaticImage
              src="../images/magic-sparkle.jpg"
              alt="Magic Collection - Sparkle"
              width={400}
              placeholder="blurred"
              quality={90}
            />
            <div className="description">Sparkle</div>
          </Link>
          <Link to="/loja/passion-magic-starter-kit/">
            <StaticImage
              src="../images/magic-passion.jpg"
              alt="Passion - Magic Starter Kit"
              width={400}
              placeholder="blurred"
              quality={90}
            />
            <div className="description">Passion - Magic Starter Kit</div>
          </Link>
          <Link to="/loja/double-trouble-magic-eyeliners/">
            <StaticImage
              src="../images/eyeliner-double-trouble.jpg"
              alt="Eyeliner Double Trouble Kit"
              width={400}
              placeholder="blurred"
              quality={90}
            />
            <div className="description">Double Trouble Kit</div>
          </Link>
        </div>
        <div className="center">
          <Link to="/loja/" className="btn">
            Ver todos
          </Link>
        </div>
      </div>
    </BestSellersSection>
  )
}

export default BestSellersMagic
