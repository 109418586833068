import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

const BestSellersSection = styled.section`
  h2 {
    margin-bottom: 6rem;
    span {
      color: #e8b795;
    }
  }
  .description {
    padding: 1rem 0 1.6rem;
    font-weight: 700;
    font-size: 1.6rem;
    text-transform: uppercase;
    text-align: center;
    color: #e8b795;
  }
  a {
    border-bottom: none;
  }
`

const BestSellers = () => {
  return (
    <BestSellersSection>
      <div className="large-container">
        <div className="center">
          <h2>
            Magnetic Collection
            <br />
            <span>Os Mais Vendidos</span>
          </h2>
        </div>
        <div className="grid-3x">
          <Link to="/loja/starter-kit/">
            <StaticImage
              src="../images/Bold-Starter-Kit.jpg"
              alt="Bold Starter Kit"
              width={400}
              placeholder="blurred"
              quality={90}
            />
            <div className="description">Bold Starter Kit</div>
          </Link>
          <Link to="/loja/pheme-starter-kit/">
            <StaticImage
              src="../images/Pheme-Starter-Kit.jpg"
              alt="Pheme Starter Kit"
              width={400}
              placeholder="blurred"
              quality={90}
            />
            <div className="description">Pheme Starter Kit</div>
          </Link>
          <Link to="/loja/eyeliner-magnetico-boomsy/">
            <StaticImage
              src="../images/EyelinerMagnetico.jpg"
              alt="Eyeliner magnético Boomsy"
              width={400}
              placeholder="blurred"
              quality={90}
            />
            <div className="description">Eyeliner Magnético</div>
          </Link>
        </div>
        <div className="center">
          <Link to="/loja/" className="btn">
            Ver todos
          </Link>
        </div>
      </div>
    </BestSellersSection>
  )
}

export default BestSellers
