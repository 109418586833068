import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"

const BestOfYouSection = styled.section`
  background: var(--grey);
  h3 {
    text-transform: uppercase;
    font-family: "glacial_bold", sans-serif;
    color: #ff9fbc;
    font-size: 5rem;
    padding-bottom: 2rem;
  }
  p {
    font-weight: 400;
    font-size: 1.6rem;
  }
`

const BestOfYou = () => {
  return (
    <BestOfYouSection>
      <div className="container grid-2x">
        <div>
          <StaticImage
            src="../images/SMILE-VERSAO-100.jpg"
            alt="Boomsy Magic smile"
            placeholder="blurred"
            quality={90}
          />
        </div>
        <div>
          <h3>A tua melhor versão</h3>
          <p>
            Todas nós somos perfeitas. A Boomsy apenas existe para realçar a tua
            confiança e beleza natural.
          </p>
          <p>
            As nossas pestanas simplificam a tua rotina para que te possas focar
            no que é mais importante para ti, sentindo-te sempre MARAVILHOSA!
          </p>
          <div>
            <Link to="/loja/" className="btn">
              As nossas Colecções
            </Link>
          </div>
        </div>
      </div>
    </BestOfYouSection>
  )
}

export default BestOfYou
