import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Benefits from "./benefits"

const Header = styled.header`
  background: var(--black);
  .lead-text {
    text-transform: uppercase;
    font-size: 4.2rem;
    font-weight: 700;
    max-width: 14ch;
    margin: 0 auto;
  }
  video {
    max-width: 100%;
  }
`

const Hero = () => {
  return (
    <Header>
      <Link to="/loja/">
        <div className="mobile-only">
          <StaticImage
            src="../images/BG-MB-NEWJUNHO.jpg"
            alt="Boomsy Hero mobile image"
            loading="eager"
            backgroundColor="black"
            layout="fullWidth"
            quality={90}
          />
        </div>
        <div className="mobile-none">
          <StaticImage
            src="../images/BG-BOOM-NEWJUNHO.jpg"
            alt="Boomsy Hero image"
            loading="eager"
            backgroundColor="black"
            layout="fullWidth"
            quality={90}
          />
        </div>
      </Link>
      <Benefits />
    </Header>
  )
}

export default Hero
